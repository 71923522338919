.permission-container {
  border: 1px solid var(--gray-600);
  border-radius: 8px;

  .custom-nav {
    border-bottom: 1px solid var(--gray-neutral-200);
    // flex-wrap: nowrap;
    // overflow-x: scroll;
    // overflow-y: hidden;
    .nav-item {
      @extend .text-semibold-sm;

      .nav-link {
        color: var(--gray-modern-400);
        &.active {
          background: transparent;
          color: var(--gray-neutral-700);
          border-color: var(--primary-500);
          &:hover {
            background: transparent;
            color: var(--gray-neutral-700);
            border-color: var(--primary-500);
          }
        }
        &:hover {
          color: var(--gray-neutral-700);
          background: transparent;
          border-color: transparent;
        }
      }
    }
  }

  ::-webkit-scrollbar {
    height: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--gray-modern-300);
    border-radius: 5px;
  }
  ::-webkit-scrollbar-track {
    background: var(--gray-modern-50);
    border-radius: 5px;
  }
}
