.my-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.mt-32 {
  margin-top: 32px;
}

.mb-32 {
  margin-bottom: 32px;
}

.m-24 {
  margin: 24px;
}

.my-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.mt-24 {
  margin-top: 24px;
}

.mb-24 {
  margin-bottom: 24px;
}

.m-20 {
  margin: 20px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.my-6 {
  margin-top: 6px;
  margin-bottom: 6px;
}

.mt-6 {
  margin-top: 6px;
}

.mb-6 {
  margin-bottom: 6px;
}

.gap-20 {
  gap: 20px;
}

.ms-12 {
  margin-left: 12px;
}

.me-12 {
  margin-right: 12px;
}

// Padding
.py-32 {
  padding: 32px;
  padding-bottom: 32px;
}

