// .message-container {
//     width: 100%;
//     margin: 0;
//     transition: 0.15s;

//     &.fixed {
//         width: 75%;
//         transition: 0.15s;
//     }
// }

.message-info-container {
    background-color: var(--base-white);
    border-left: 1px solid var(--gray-modern-200);
    height: 100%;
    position: fixed;
    right: -100%;
    transition: all 0.15s;
    margin: 0;
    padding: 0;

    &.fixed {
        right: 0;
        transition: 0.15s;
        position: absolute;
    }

    .list-chat-member {
        overflow: auto;
        height: calc(100vh - 72px - 80px - 64px - 64px);
    }
}

.chat-info-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--gray-modern-100);
    color: var(--gray-neutral-900);
    height: 80px;
    padding: 0 20px;
}

.message-info-item {
    min-height: 64px;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--gray-modern-100);
    color: var(--gray-neutral-900);
    padding: 0 20px;
}

.add-member-icon {
    color: var(--gray-modern-400);
    width: 32px;
    height: 32px;
    border: 1px dashed var(--gray-modern-300);
    cursor: pointer;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.message-info-member {
    height: 64px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.list-chat-container {
    border-right: 1px solid var(--gray-modern-200);
    height: 100%;
    padding: 0;

    .chat-info-header {
        @extend .text-xs;
        color: var(--gray-modern-500);

        svg {
            margin-right: 4px;
            color: var(--gray-modern-700);
        }

        span {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }

    .list-chat {
        overflow: auto;
        height: calc(100vh - 152px);

        .chat-room {
            border-bottom: 1px solid var(--gray-modern-100);
            padding: 16px 20px;
            display: flex;
            cursor: pointer;

            &:hover {
                background-color: var(--primary-50);
            }

            &.active {
                background-color: var(--primary-100);
            }

            .chat-room-name {
                @extend .text-medium-sm;
                color: var(--gray-neutral-950);
            }

            .noti-badge {
                @extend .text-medium-xs;
                background-color: var(--gray-modern-200);
                border-radius: 16px;
                color: var(--gray-mordern-500);
                padding: 0 4px;
            }
        }
    }
}

.message-box {
    width: 100%;
    height: 100%;
    margin: 0;
    transition: 0.15s;
    position: relative;

    .chat-box-container {
        overflow: auto;
        height: calc(100vh - 72px - 80px - 72px);
        padding: 20px;

        .chat-box {
            background-color: var(--gray-modern-100);
            color: var(--gray-modern-900);
            padding: 9px 12px;
            border-radius: 8px;
            max-width: 300px;

            &.primary {
                background-color: var(--blue-dark-500);
                color: var(--base-white);
            }
        }
    }

    .chat-input-container {
        // position: absolute;
        // bottom: 0;
        padding: 12px 20px;
        border-top: 1px solid var(--gray-modern-100);
        width: 100%;
        background-color: var(--base-white);

        .form-control {
            @extend .text-xs;
            border-radius: 18px;
            height: 48px;
            border: none;
            padding: 16px;
            color: var(--gray-modern-400);
            background-color: var(--gray-modern-100);

            &:focus {
                box-shadow: none;
            }

            &::placeholder {
                color: var(--gray-modern-400);
            }
        }

        .input-group-text {
            border-radius: 18px;
            border: none;
            color: var(--gray-modern-400);
            padding: 14px 16px;
            background-color: var(--gray-modern-100);
            cursor: pointer;
            color: var(--gray-modern-400);

            &:hover {
                svg {
                    color: var(--primary-500);
                }
            }
        }
    }
}

// /* Chọn thanh cuộn trên trình duyệt Chrome/Safari/Edge */
// ::-webkit-scrollbar {
//     width: 8px;
//     /* Độ rộng của thanh cuộn */
// }

// /* Chọn thanh cuộn trên trình duyệt Firefox */
// /* Không phải tất cả các trình duyệt đều hỗ trợ */
// /* Bạn có thể cần sử dụng một phần mềm tiện ích để thay thế */
// * {
//     scrollbar-color: red yellow;
//     /* Màu của thanh cuộn (Firefox) */
// }

// /* Track của thanh cuộn (phần nền) */
// ::-webkit-scrollbar-track {
//     background-color: var(--base-white);
//     /* Màu nền của track */
// }

// /* Handle của thanh cuộn (phần di chuyển) */
// ::-webkit-scrollbar-thumb {
//     background-color: var(--gray-neutral-200);
//     /* Màu của handle */
//     border-radius: 6px;
//     /* Bo góc của handle */
// }

// /* Hover effect cho handle */
// ::-webkit-scrollbar-thumb:hover {
//     background-color: var(--gray-neutral-300);
//     /* Màu khi hover */
// }

@media only screen and (max-width: 767px) {
    .chat-container {
        position: relative !important;
    }

    .list-chat-container {
        border-bottom: 1px solid var(--gray-modern-100);

        .list-chat {
            height: 72px !important;
        }
    }

    .message-box {
        .chat-box-container {
            height: calc(100vh - 72px - 80px - 72px - 80px - 72px) !important;
        }
    }

    .message-info-container {
        height: calc(100vh - 72px - 80px - 72px) !important;

        .list-chat-member {
            height: calc(100% - 80px - 64px - 64px) !important;
        }
    }
}