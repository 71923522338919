.task-detail-modal {
  .modal-dialog {
    max-width: 600px;

    // min-height: 830px;
    .modal-content {
      height: 830px;
      background-color: var(--base-white);
      border-radius: 8px;
      box-shadow: none;

      .modal-header {
        padding: 20px 32px;
        border-bottom: none;
        padding: 20px 24px 12px 24px;

        .btn-close {
          align-self: flex-start;
        }

        .header-container {
          width: 100%;

          .breadcrumb-container {
            margin-bottom: 12px;

            .breadcrumb {
              @extend .text-medium-sm;
              color: var(--gray-modern-300);

              .breadcrumb-icon {
                width: 16px;
                height: 16px;
                transform: translate(10%, 15%);
                stroke: var(--gray-modern-300);
              }
            }

            .close-modal-icon {
              stroke: var(--base-white);
            }
          }

          .title-container {
            margin-bottom: 18px;
            gap: 8px;
            align-items: center;

            svg {
              stroke: var(--base-white);
            }

            .head-title {
              @extend .text-md;
              color: var(--base-white);
            }
          }

          .user-addmore-container {
            .dropdown-menu {
              padding: 0;
              min-width: 300px;

              .dropdown-header {
                @extend .text-medium-xs;
                color: var(--gray-modern-500);
                padding: 12px 8px;

                .header-content {
                  width: 100%;
                  position: relative;

                  .close-dropdown-icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                    stroke: var(--gray-modern-400);
                  }
                }
              }

              .dropdown-body {
                padding: 20px 12px;

                .user-list-checkbox {
                  margin-top: 16px;

                  .checkbox-label {
                    @extend .text-medium-xs;
                    color: var(--gray-modern-500);
                    margin-bottom: 8px;
                  }
                }
              }
            }
          }
        }
      }

      .modal-body {
        padding: 0;

        .modal-tab-container {
          background-color: var(--base-white);
          border-bottom: 1px solid var(--gray-modern-200);
          display: flex;
          padding: 0 32px;
          align-items: center;
          width: 100%;

          .custom-nav {
            border-bottom: none;
            gap: 20px;

            .nav-item {
              @extend .text-medium-sm;

              .nav-link {
                padding: 18px 0;
                color: var(--gray-modern-400);

                svg {
                  stroke: var(--gray-modern-400);
                }

                &.active {
                  background: transparent;
                  border-color: transparent;
                  box-shadow: 0px -3px 0px 0px var(--blue-600) inset;
                  color: var(--gray-modern-900);

                  svg {
                    stroke: var(--gray-modern-900);
                  }

                  &:hover {
                    background: transparent;
                    border-color: transparent;
                    box-shadow: 0px -3px 0px 0px var(--blue-600) inset;
                    color: var(--gray-modern-900);

                    svg {
                      stroke: var(--gray-modern-900);
                    }
                  }
                }

                &:hover {
                  color: var(--gray-modern-900);
                  background: transparent;
                  border-color: transparent;

                  svg {
                    stroke: var(--gray-modern-900);
                  }
                }
              }
            }
          }
        }

        .tab-container {
          height: 100%;
          .tab-pane {
            height: 100%;
          }
          .tab-content {
            padding: 32px;
            max-height: 627px;
            overflow: auto;
            height: 100%;
          }

          .subtask-tab {
            .subtask-header {
              .subtask-display {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 8px;

                .display-type {
                  cursor: pointer;

                  svg {
                    stroke: var(--gray-modern-300);
                  }

                  &.active {
                    svg {
                      stroke: var(--gray-modern-900);
                    }
                  }
                }
              }
            }

            .subcard-container {
              gap: 20px;
              --cui-backdrop-zindex: 1055;

              .subtask-card {
                cursor: pointer;
                width: 248px;
                height: 148px;
                border-radius: 8px;
                border: 1px solid var(--gray-modern-200);
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .subtask-header {
                  .subtask-title {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .info-text {
                      @extend .text-xs;
                      color: var(--gray-modern-500);
                    }
                  }

                  .subtask-status {
                    @extend .text-sm;
                    color: var(--gray-modern-400);
                  }
                }

                .subtask-body {
                  padding: 12px 0;

                  .subtask-name {
                    @extend .text-medium-sm;
                    color: var(--gray-modern-900);
                  }
                }

                .subtask-footer {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  .task-info-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 12px;
                  }
                }
              }
            }
          }

          .infomation-tab {
          }

          .result-tab {
            .result-content-container {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              gap: 10px;

              .result-content {
                width: 100%;

                .content-header {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  .user-name {
                    @extend .text-medium-sm;
                    color: var(--gray-modern-900);
                  }

                  .created-date {
                    @extend .text-xs;
                    color: var(--gray-modern-500);
                  }
                }

                .content-body {
                }
              }
            }

            .result-comment-container {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              gap: 12px;

              .result-comment-wrapper {
                width: 100%;

                .comment-body {
                }
              }
            }

            .result-file-container {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-wrap: wrap;
              gap: 20px;

              .result-file-card {
                width: 248px;
                height: 258px;
                border-radius: 8px;
                border: 1px solid var(--gray-modern-200);
                background-color: var(--base-white);
                padding: 12px;

                .file-cover {
                  width: 224px;
                  height: 140px;
                  border-radius: 2px;
                  margin-bottom: 8px;
                  position: relative;
                  display: flex;
                  justify-content: flex-end;
                  align-items: flex-end;
                  padding: 9px;
                  background-color: var(--gray-modern-50);

                  img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    border-radius: 2px;
                  }

                  .upload-icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }

                  .file-status {
                    // position: absolute;
                    // bottom: 0;
                    // right: 0;
                    // transform: translate(-9px, -9px);
                  }
                }

                .file-information {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  gap: 10px;

                  .file-name {
                    @extend .text-sm;
                    color: var(--gray-modern-900);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 222px;
                  }

                  .file-detail-info {
                    @extend .text-xs;
                    color: var(--gray-modern-500);
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  }

                  .file-other-info {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 9px;
                  }
                }
              }
            }

            .function-container {
              @extend .text-sm;
              color: var(--gray-modern-500);
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 16px;
            }
          }

          .discuss-tab {
            gap: 32px;
            display: grid;
          }
        }
      }

      .modal-footer {
      }

      .btn-custom {
      }
    }
  }
}

.task-info {
  @extend .text-xs;
  color: var(--gray-modern-500);
  display: flex;
  justify-content: flex-start;
  padding: 0px 2px;
  align-items: center;
  gap: 4px;

  svg {
    stroke: var(--gray-modern-500);
  }
}

.file-preview-modal {
  .modal-content {
    background-color: var(--base-black);
    border-radius: 8px;
    box-shadow: none;

    .modal-header {
      @extend .text-medium-md;
      border-bottom: none;
      padding: 36px 30px;
      color: var(--base-white);

      .header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header-title {
          @extend .text-medium-md;
          color: var(--base-white);
          display: flex;
          align-items: center;
          justify-content: flex-start;

          svg {
            stroke: var(--base-white);
          }
        }

        .info-content {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 12px;

          .task-info-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 9px;

            .task-info {
              color: var(--base-white);

              svg {
                stroke: var(--base-white);
              }
            }
          }
        }

        .other-function {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 8px;
        }
      }
    }

    .modal-body {
      @extend .text-semibold-sm;
      color: var(--gray-modern-700);
      padding: 0;
      .file-container {
        background-color: var(--gray-modern-600);
        min-width: 500px;
        border-radius: 12px;
        text-align: center;
        padding: 20px;
        .file-text {
          @extend .text-medium-lg;
          color: var(--base-white);
          margin-bottom: 18px;
        }
      }
      .image-container {
        background-color: var(--base-white);
        border-radius: 2px;
        height: inherit;
      }

      .player-ratio-box {
        // width: inherit;
        height: inherit;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: inherit;
      }

      video {
        object-fit: cover;
        width: 100%;
        height: inherit;
      }
    }

    .modal-footer {
      background-color: var(--base-black);
      padding: 0;
      min-height: 100px;
      border: none;
    }

    .btn-custom {
      &.minw-120 {
        min-width: 120px;
      }

      &.minw-44 {
        min-width: 44px;
      }
    }
  }
}
