html,
body {
  font-family: 'Times New Roman', Times, serif !important;
}

.container-web {
  padding-top: 80px;
  padding-left: 200px;
  background-color: #f0f0f0;
  min-height: 100vh;

  .header-web {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 1470px;
    height: 80px;
    background-image: linear-gradient(90deg, #4d3f74, #80588d 52%, #a66892);
    z-index: 111;

    .logo {
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #eac973;
      flex-grow: 0;
      flex-shrink: 0;
    }

    .menu-left {
      a {
        display: inline-block;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        text-decoration: none;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .menu-right {
      .nap-tien,
      .rut-tien {
        border-radius: 4px;
        padding: 2px 11px;
        color: #000;
        background: linear-gradient(180deg, #fff, #f7dbf3);
        font-size: 13px;
        text-decoration: none;
      }
    }
  }

  .content-header {
    width: 1210px;
    margin: 15px auto 0;
  }

  .page-body-web {
    display: flex;
    flex-wrap: wrap;
    width: 1210px;
    margin: 15px auto;

    .sidebar-web {
      position: fixed;
      left: 0;
      top: 0;
      width: 200px;
      height: 100%;
      padding: 80px 0 0;
      overflow: auto;
      background-color: #6a4e85;
      z-index: 11;

      .parent-item {
        color: #fff;

        .parent-title {
          background-color: #6a4e85;
          display: flex;
          height: 60px;
          cursor: pointer;
          align-items: center;
          padding-left: 20px;
          padding-right: 20px;
          border: 1px solid #82659f;

          svg {
            transform: rotate(90deg);
          }
        }

        span {
          font-size: 14px;
        }

        .child-item {
          background-color: #7c5a9c;

          .item {
            cursor: pointer;
            padding-left: 30px;
            color: #feebb6;
            height: 32px;
            align-content: center;
            display: flex;
            text-decoration: none;
            align-items: center;
          }
        }
      }
    }

    .content-center {
      width: 840px;
    }

    .content-right {
      width: 350px;
      margin-left: 20px;
    }
  }
}

.login-fe-custom {
  background-image: url('../../img/login-bg3.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .wrapper-login {
    width: 700px;

    .box-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #fff;

      .item-form {
        margin-top: 25px;

        input {
          border: none;
          padding: 0;
          font-size: 12px;
          height: 30px;
        }
      }

      .btn-custom {
        height: 40px;
        font-size: 13px;
        margin-top: 40px;
        color: #fff;
        background-color: #351c4e;
      }
    }
  }
}

.home-web {
  .content-center {
    .banner-top {
      .carousel-inner {
        height: 370px;
        text-align: center;
        background-color: #000;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .content-1 {
      padding: 15px;
      margin-top: 20px;
      background-color: #fff;
    }
  }

  .content-right {
    .item-right {
      margin-bottom: 20px;

      .header-item {
        justify-content: space-between;
        background-color: #965e85;
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        display: flex;
        align-items: center;
        height: 40px;

        div {
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &.active {
            background-color: #bb7ea8;
          }
        }
      }

      .content-item {
        padding-left: 10px;
        background-color: #fff;
        height: 500px;
        overflow-y: auto;

        &.list-bxh {
          &::-webkit-scrollbar {
            width: 0;
          }
        }


        .tab1 {
          .content-class {
            height: 34px !important;

            .item-bi-quay {
              gap: 3px;
              height: 61px;
              align-items: center;

              .item {
                width: 18px;
                height: 21px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 10px;
                border-radius: 4px;
                font-family: Helvetica;
                color: #fff;
                text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.5);

                &.item-0 {
                  background-color: #e4e202;
                }

                &.item-1 {
                  background-color: #0492da;
                }

                &.item-2 {
                  background-color: #979797;
                }

                &.item-3 {
                  background-color: #f7770a;
                }

                &.item-4 {
                  background-color: #1edee9;
                }

                &.item-5 {
                  background-color: #4f32fe;
                }

                &.item-6 {
                  background-color: #bfbfbf;
                }

                &.item-7 {
                  background-color: #ff2401;
                }

                &.item-8 {
                  background-color: #770b00;
                }

                &.item-9 {
                  background-color: #03bc00;
                }
              }
            }
          }
        }

        .tab2,
        .tab1 {
          .header-class {
            font-size: 14px;
            border-bottom: 1px solid #e6e6e6;
            height: 40px;
            align-items: center;
          }

          .list-content-sc {
            height: 410px;
            overflow: auto;
            font-size: 12px;
            color: #666;
          }

          .content-class {
            font-size: 12px;
            border-bottom: 1px solid #e6e6e6;
            height: 66px;
            align-items: center;
          }
        }
      }
    }
  }
}

.game-web {
  .content-header {
    display: flex;
    align-items: center;
    height: 120px;
    border: 7px solid #6d4f84;
    border-radius: 10px;
    margin-bottom: 20px;

    .time-quay-so {
      .item {
        font-size: 43px;
        font-family: DINAlternate-Bold !important;
        color: #fff;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        line-height: 0;
        height: 51px;
        margin-right: 10px;
        background: url('../../img/quayso.png') center center no-repeat;
        background-size: contain;
      }
    }

    .item-bi-quay {
      gap: 3px;
      height: 61px;
      align-items: center;

      .item {
        width: 27px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 21px;
        border-radius: 5px;
        font-family: Helvetica;
        color: #fff;
        text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.5);

        &.item-0 {
          background-color: #e4e202;
        }

        &.item-1 {
          background-color: #0492da;
        }

        &.item-2 {
          background-color: #979797;
        }

        &.item-3 {
          background-color: #f7770a;
        }

        &.item-4 {
          background-color: #1edee9;
        }

        &.item-5 {
          background-color: #4f32fe;
        }

        &.item-6 {
          background-color: #bfbfbf;
        }

        &.item-7 {
          background-color: #ff2401;
        }

        &.item-8 {
          background-color: #770b00;
        }

        &.item-9 {
          background-color: #03bc00;
        }
      }
    }

    .action-btn {
      .item {
        display: flex;
        align-items: center;
        width: 124px;
        line-height: 24px;
        padding: 0 5px;
        border-radius: 4px;
        background-image: linear-gradient(0deg, #715186, #b0709d),
          linear-gradient(#3b7cec, #3b7cec);
        background-blend-mode: normal, normal;
        text-align: center;
        cursor: pointer;
        flex: 1;
        font-size: 13px;
        text-align: left;
        color: #fff;
      }
    }
  }

  .page-body-web {
    .content-center {
      background-color: #fff;

      .header-content-center {
        background-color: #965e85;
        font-weight: 700;
        color: #fff;
        display: flex;
        align-items: center;

        .item {
          font-size: 13px;
          display: flex;
          align-items: center;
          padding: 0 10px;
          height: 40px;
          cursor: pointer;

          &.active {
            background-color: #bb7ea8;
          }
        }
      }

      .content-content-center {
        .box-top {
          font-size: 13px;
          color: #666;
          border-bottom: 1px dashed #dfdfdf;

          .amount {
            width: 43px;
            height: 26px;
            border-radius: 6px;
            border: 1px solid #d2d2d2;
            margin-right: 8px;
            text-align: center;
            background-color: #fff;
            outline: none;
            font-family: times new Roman, PingFang SC, Microsoft YaHei,
              WenQuanYi Micro Hei, sans-serif !important;
          }

          .sotien {
            div {
              cursor: pointer;
              width: 45px;
              height: 45px;
              border-radius: 50%;
              font-size: 12px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #494949;
              background-size: 100% 100%;
              font-weight: 600;

              &.item1 {
                background-image: url('../../img/c1.png');
              }

              &.item2 {
                background-image: url('../../img/c2.png');
              }

              &.item3 {
                background-image: url('../../img/c3.png');
              }

              &.item4 {
                background-image: url('../../img/c4.png');
              }

              &.item5 {
                background-image: url('../../img/c5.png');
              }

              &.item6 {
                background-image: url('../../img/c6.png');
              }
            }
          }
        }

        .boxchon {
          display: grid;
          gap: 10px;
          border-bottom: 1px dashed #dfdfdf;

          .item {
            .title {
              height: 20px;
              line-height: 20px;
              padding: 15px 0;
              margin-bottom: 15px;
              font-weight: 700;
              font-size: 14px;
              color: #333;
              text-align: center;
            }

            .content {
              display: flex;
              flex-wrap: wrap;
              padding: 0 10px 25px;

              .box {
                margin: 5px 10px 5px 0;
                border-radius: 5px;
                cursor: pointer;

                &.active {
                  background-color: #eee0f5;
                }
              }

              .name {
                width: 33% !important;
                font-size: 14px;
                font-weight: 600;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-grow: 0;
                flex-shrink: 0;
              }

              .rate {
                width: 50px;
                text-align: center;
                // font-family: DINAlternate-Bold !important;
                font-weight: 700;
                font-size: 14px;
                color: #965e85;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-grow: 0;
                flex-shrink: 0;
              }

              .amount {
                width: 42px;
                flex-grow: 0;
                flex-shrink: 0;

                input {
                  font-size: 13px;
                  width: 100%;
                  outline: none !important;
                  height: 24px;
                  line-height: 24px;
                  border: 1px solid #d2d2d2;
                  border-radius: 3px;
                  text-align: center;
                  background-color: #fff;
                  box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.25);
                }
              }
            }
          }
        }

        .btn-datcuoc {
          width: 116px;
          height: 40px;
          line-height: 40px;
          border-radius: 5px;
          text-align: center;
          font-size: 14px;
          color: #fff;
          background-color: #f49400;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          cursor: pointer;
        }
      }

      .box-lich-su-cuoc {
        .head-lich-su-cuoc {
          background-color: #965e85;
          // border-bottom: 1px dashed #dfdfdf
        }

        .header-class {
          height: 40px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #e6e6e6;
        }

        .list-content-sc {
          height: 300px;
          overflow-y: auto;
        }
        .content-class {
          font-size: 12px;
          height: 35px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #e6e6e6;
        }
      }
    }
  }
}

.custom-modal-datcuoc-web {
}

.icon-sidebar {
  transform: rotate(90deg);
}

.page-rut-tien-web {
  .content-header {
    .header-top {
      a {
        height: 45px;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #666;
        font-size: 14px;

        &.active {
          background-color: #965e85;
          color: #fff;
        }
      }
    }

    .header-bottom {
      margin-top: 15px;
      background-color: #fff;

      .top {
        border-bottom: 1px solid #dfdfdf;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 54px;
          font-size: 14px;
          text-decoration: none;
          padding: 0 10px;
          color: #666;

          &.active {
            color: #f49400;
            font-weight: 700;
            border-bottom: 3px solid #f49400;
          }
        }
      }

      .avatar-top {
        border: 1px solid #979797;
      }

      .info-right {
        border-radius: 5px;
        background-color: #f8f8f8;
      }
    }
  }
}

.custom-modal-notify {
  border-radius: 10px;

  .content-c {
    height: 300px;
    overflow-y: auto;
  }

  .title {
    font-size: 40px;
    font-weight: 700;
    color: #ff7f69;
    text-align: left;
    text-shadow: 2px 2px rgba(255, 127, 105, 0.35);
    padding: 0 20px;
    margin-bottom: 10px;
  }

  .bg {
    border-radius: 25px !important;
  }

  button {
    background-color: #965e85;
  }

  .modal-content {
    border-radius: 25px !important;
  }
}

.page-lich-su-rut-tien-web {
  .top {
    div {
      height: 40px;
      display: flex;
      align-items: center;
      font-weight: 600;
    }
  }

  .item-content {
    min-height: 35px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
  }
}

.box-chat-customer-web {
  .chat-box-container {
    height: calc(100vh - 240px) !important;
  }

  .chat-info-header {
    background: linear-gradient(
      93.19deg,
      rgba(82, 49, 98, 0.85),
      rgba(129, 74, 149, 0.85) 48.92%,
      rgba(201, 133, 145, 0.85) 98.86%
    ) !important;
  }
  .menu-bottom-wap {
    display: none !important;
  }

  .info-current-user {
    display: none !important;
  }

  .container-wap {
    padding-bottom: 0 !important;
  }
}

.caidat-chip {
  background-color: #f49400;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.custom-modal-catdat-tien {
  .item-cuoc {
    cursor: pointer;
    width: 85px;
    height: 85px;
    border-radius: 50%;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #494949;
    background-size: 100% 100%;
    font-weight: 600;
    filter: grayscale(100%);
    background-image: url('../../img/c6.png');

    &.active {
      filter: none;
    }

    // &.item2 {
    //   background-image: url('../../img/c2.png');
    // }

    // &.item3 {
    //   background-image: url('../../img/c3.png');
    // }

    // &.item4 {
    //   background-image: url('../../img/c4.png');
    // }

    // &.item5 {
    //   background-image: url('../../img/c5.png');
    // }

    // &.item6 {
    //   background-image: url('../../img/c6.png');
    // }
  }
}

.custom-modal-catdat-tien-mobile {
  .item-cuoc {
    width: 50px;
    height: 50px;
    font-size: 14px;
  }
}

.header-top-wap-game {
  .menu-mobile {
    top: 48px;
    left: 0;
    right: 0;
    min-height: calc(100vh - 48px);
    background-color: #fff;
    z-index: 999999;
    position: fixed;
    padding: 10px;
    height: calc(100vh - 48px);
    overflow-y: auto;
    .box-list {
      .item {
        display: block;
        width: 29.06667vw;
        height: 8.53333vw;
        line-height: 8.53333vw;
        margin-right: 2.66667vw;
        margin-top: 2.66667vw;
        border-radius: 1.06667vw;
        text-align: center;
        color: #4b5670;
        background: #f3f5fc;
        font-size: 10px;
      }
    }
  }
}

.custom-editor-settings {
  width: 100%;
  // height: 300px;
  margin: 0 auto;
  .ck-editor__editable_inline {
    min-height: 300px;
    height: 100%;
  }
}

.tet-app {
  &::-webkit-scrollbar {
    width: 0;
  }
  &.App {
    overflow: hidden;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  .card {
    width: 200px !important;
    height: 200px;
    background: #ffefef;
    box-shadow: 0px 1px 4px 1px rgba(158, 151, 151, 0.25);
    border-radius: 15px;
    margin: 12px;
    padding: 12px;
  }
  .list-item-bxh {
    overflow: visible;
    animation: animateContainer 20s linear forwards infinite;
    flex-direction: column;
  }
  @keyframes animateContainer {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-50%);
    }
  }
}
