.custom-tree {
  .info-text {
    @extend .text-xs;
    color: var(--gray-modern-500);
  }

  .folder-template {
    height: 100%;
    border-radius: 2px;
    background-color: var(--blue-500);
  }

  .task-template {
    height: 100%;
    border-radius: 2px;
    background-color: var(--green-400);
  }

  .e-gantt-chart {
    .e-gantt-parent-taskbar-inner-div {
      background-color: var(--blue-500);
      outline: 1px solid var(--blue-500);
      border-radius: 2px;
    }

    .e-gantt-manualparenttaskbar-left,
    .e-gantt-manualparenttaskbar-right {
      background-color: var(--blue-500);
      border-color: var(--blue-500);
    }

    .e-gantt-manualparenttaskbar {
      background: var(--blue-500);
    }
  }

  .e-timeline-header-table-container {
    thead {
      th {
        background-color: var(--gray-modern-50);
        .e-header-cell-label {
          @extend .text-medium-xs;
          color: var(--gray-modern-700);
        }
      }
    }
  }
  .e-table {
    thead {
      th {
        @extend .text-medium-xs;
        color: var(--gray-modern-700);
        background-color: var(--gray-modern-50);
      }
    }
    tbody {
      .e-icons,
      .e-treegridcollapse {
        vertical-align: top;
        &.e-none {
          &::before {
            content: '';
          }
        }
        &::before {
          content: url('../../img/chevron-right-fix.svg');
        }
      }
    }
  }

  // .e-chart-scroll-container {
  //   &.e-content {
  //     ::-webkit-scrollbar {
  //       height: 3px;
  //     }
  //     ::-webkit-scrollbar-thumb {
  //       background: var(--gray-800);
  //       border-radius: 5px;
  //     }
  //     ::-webkit-scrollbar-track {
  //       background: var(--gray-900);
  //       border-radius: 5px;
  //     }
  //   }
  // }

  // .e-gridcontent {
  //   .e-content {
  //     border: 1px solid red;

  //     ::-webkit-scrollbar {
  //       height: 5px;
  //     }
  //     ::-webkit-scrollbar-thumb {
  //       background: red;
  //       border-radius: 5px;
  //     }
  //     ::-webkit-scrollbar-track {
  //       background: var(--gray-900);
  //       border-radius: 5px;
  //     }
  //   }
  // }


}
