.avatar-88 {
  width: 88px;
  height: 88px;
}

.avatar-32 {
  width: 32px;
  height: 32px;
}

.avatar-48 {
  width: 48px;
  height: 48px;
}

.avatar-40 {
  width: 40px;
  height: 40px;
}

.avatar-24 {
  width: 24px;
  height: 24px;
}

.avatar-20 {
  width: 20px;
  height: 20px;
}

.avatar-16 {
  width: 16px;
  height: 16px;
}

.sidebar-avatar {
  @extend .text-xs;
  border-radius: 2px;
}

// .list-avatar-container {
//   display: flex;
//   justify-content: flex-start;
//   .avatar-wrapper {
//     border: 2px solid var(--base-white);
//     // box-shadow: -2px -2px -2px -2px var(--base-white) inset;

//     &.size-32 {
//       @extend .avatar-32;
//     }

//     &.size-48 {
//       @extend .avatar-48;
//     }

//     &.size-40 {
//       @extend .avatar-40;
//     }

//     &.size-24 {
//       @extend .avatar-24;
//     }

//     &.extends-avatar {
//       @extend .text-medium-xs;
//       cursor: default;
//       background-color: var(--gray-modern-500);
//     }
//   }
// }

.avatar-addmore {
  background-color: var(--gray-modern-100);
  padding: 0;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  border-radius: 50em !important;
  svg {
    width: 16px;
    height: 16px;
    stroke: var(--gray-modern-400);
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--gray-modern-400) !important;
    background-color: var(--gray-modern-100) !important;
  }
}

.avatar-checkbox {
  position: relative;
  .form-check-input {
    position: absolute;
    bottom: 0;
    right: 0;

    min-width: 18px;
    min-height: 18px;
    margin-top: 0;
    box-shadow: none !important;
    background-color: var(--base-white);
    border: 2px solid var(--gray-neutral-300);
    border-radius: 6px;

    &:checked {
      background-color: var(--blue-600);
      border: 1px solid var(--base-white);
    }

    &:disabled {
      opacity: 1;
    }
  }
}

.list-avatar-container {
  position: relative;
  .avatar-wrapper {
    position: absolute;
    top: 0;
    border: 2px solid var(--base-white);
    // box-shadow: -2px -2px -2px -2px var(--base-white) inset;

    &.size-32 {
      @extend .avatar-32;
    }

    &.size-48 {
      @extend .avatar-48;
    }

    &.size-40 {
      @extend .avatar-40;
    }

    &.size-24 {
      @extend .avatar-24;
    }

    &.size-16 {
      @extend .avatar-16;
    }

    &.extends-avatar {
      @extend .text-medium-xs;
      cursor: default;
      background-color: var(--gray-modern-500);
    }
  }
}

.avatar-img {
  height: inherit;
  object-fit: cover;
  border-radius: inherit;
}
