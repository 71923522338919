.edit-avatar {
  position: absolute;
  top: 46px;
  left: 46px;
  background: #f2f4f7;
  height: 20px;
  width: 20px;
  border-radius: 12px;
  padding: 4px;
  cursor: pointer;
}

.avatar-container {
  height: 64px;
  width: 64px;
  padding: 0;
  position: relative;
}

.avatar-size-2xl {
  height: 64px;
  width: 64px;
}

.footer-submit {
  position: sticky;
  bottom: 0;
  background: var(--gray-neutral-800);
}
