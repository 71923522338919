.document-page-wrapper {
  .breadcrumb-container {
    gap: 10px;
    align-items: center;

    .breadcrumb-toggle {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      .dot-icon {
        transform: rotate(90deg);
      }

      .chevron-icon {
        width: 14px;
        height: 14px;
        stroke: var(--gray-modern-700);
      }
    }

    .breadcrumb {
      @extend .text-medium-sm;
      color: var(--gray-modern-700);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      .breadcrumb-icon {
        width: 14px;
        height: 14px;
        stroke: var(--gray-modern-700);
      }
    }
  }

  .empty-document-container {
    .empty-document {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .folder-container {
    margin-top: 12px;
    margin-bottom: 30px;

    .folder-card-wrapper {
      flex: 0;
      .folder-file-card {
        width: 248px;
        min-height: 64px;
        border-radius: 8px;
        border: 1px solid var(--gray-modern-200);
        background-color: var(--base-white);
        padding: 20px;

        .file-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .file-name {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 9px;
            @extend .text-medium-sm;
            color: var(--gray-modern-900);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 195px;
            svg {
              width: 16px;
              height: 16px;
              stroke: var(--gray-modern-900);
            }
          }

          .more-icon {
            width: 20px;
            height: 20px;
            stroke: var(--gray-modern-400);
            cursor: pointer;
          }
        }
      }
    }
  }

  .document-label {
    @extend .text-medium-sm;
    color: var(--gray-modern-700);
  }

  .document-container {
    margin-top: 12px;
    margin-bottom: 30px;
    .document-card-wrapper {
      flex: 0;
      .document-file-card {
        width: 240px;
        min-height: 240px;
        border-radius: 8px;
        border: 1px solid var(--gray-modern-200);
        background-color: var(--base-white);
        padding: 10px;

        .file-cover {
          width: 220px;
          height: 160px;
          border-radius: 2px;
          margin-bottom: 10px;
          position: relative;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          padding: 9px;
          background-color: var(--gray-neutral-50);

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 2px;
          }

          .upload-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 88px;
            height: 88px;
            transform: translate(-50%, -50%);
          }
        }

        .file-information {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          gap: 10px;
          .file-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .file-name {
              @extend .text-sm;
              color: var(--gray-modern-900);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 195px;
            }

            .more-icon {
              width: 20px;
              height: 20px;
              stroke: var(--gray-modern-400);
              cursor: pointer;
            }
          }

          .file-detail-info {
            @extend .text-xs;
            color: var(--gray-modern-500);
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .file-other-info {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 9px;
          }
        }
      }
    }
  }
}
