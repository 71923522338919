.color-popover-container {
  .color-popover-wrapper {
    width: 15rem;
    .react-colorful {
      width: auto;
      height: 11.5rem;
      .react-colorful__saturation {
        border-radius: 0;
      }
      .react-colorful__last-control {
        border-radius: 0;
      }
    }
  }
}

.color-picker-demo {
  @extend .text-display-semibold-sm;
  color: var(--base-white);
  border: 1px solid var(--gray-modern-300);
  border-radius: 8px;
  height: 88px;
  width: 88px;
  min-width: 88px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.color-picker-container {
  .color-picker-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .color-picker {
      display: flex;
      width: 24px;
      height: 24px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 24px;
      cursor: pointer;
      &.active {
        padding: 0;
        stroke: var(--base-white);
      }

      &.create-color {
        padding: 0;
        stroke: var(--gray-modern-500);
      }
    }
  }
}

.color-picker-toggle {
  background-color: var(--gray-modern-200);
  width: 24px;
  height: 24px;
  padding: 0px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  border-radius: 24px !important;
  box-shadow: none !important;
  svg {
    width: 20px;
    height: 20px;
    stroke: var(--gray-modern-500);
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--gray-modern-500) !important;
    background-color: var(--gray-modern-200) !important;
  }
}
