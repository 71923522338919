.avatar-upload-container {
  position: relative;

  .image-container {
    position: relative;
    border: 1px solid var(--gray-modern-300);
    border-radius: 8px;
    .avatar-img {
      object-fit: cover;
      height: 88px;
      width: 88px;
    }

    .upload-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 88px;
      height: 88px;
      .upload-icon {
        stroke: var(--gray-modern-500);
        width: 32px;
        height: 32px;
      }
    }
  }

  .p-image {
    display: none;
    .upload-button {
      display: none;
    }
  }
  .p-image-custom {
    display: none;
  }

  &:hover {
    .p-image-custom {
      bottom: 0%;
      left: 0%;
      display: block;
      position: absolute;
      display: block;
      cursor: pointer;
      .file-upload {
        display: none;
      }
      .add-image {
        width: 88px !important;
        height: 88px !important;
      }
    }

    .p-image {
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      color: #ffffffb8;
      transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      .upload-button {
        stroke: var(--primary-500);
        width: 32px;
        height: 32px;
        display: block;
        &:hover {
          transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
      }
      .file-upload {
        display: none;
      }

      &:hover {
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
    }
  }
}

.picker-text {
  @extend .text-sm;
  color: var(--gray-modern-500);
}

.upload-file-wrapper {
  .delete-all-btn {
    background-color: rgba(0, 0, 0, 0.35);
    border: none;
    color: white;
    box-shadow: none !important;
    &:hover {
      color: red;
    }
  }

  .file-container {
    // height: 2rem;
    gap: 12px;
    background-color: #fff;
    margin-bottom: 16px;

    .file-content {
      width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .file-name {
        @extend .text-sm;
        color: var(--gray-modern-700);
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
      .file-action {
        @extend .text-xs;
        text-decoration: underline;
        color: var(--gray-modern-500);
        height: fit-content;
      }
      .file-action-info {
        @extend .text-xs;
        color: var(--gray-modern-500);
        height: fit-content;

        a {
          color: var(--gray-modern-500);
        }

        svg {
          fill: var(--gray-modern-500);
        }
        .action-text {
          text-decoration: underline;
        }
      }
    }

    .file-img-contain {
      width: 72px;
      min-height: 48px;
      max-height: 72px;
      border-radius: 2px;

      &.no-img {
        background-color: var(--gray-modern-50);
        position: relative;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      .upload-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .upload-file-container {
    position: relative;
    margin: 0;
    cursor: pointer;
    width: 95px;
    height: 36px;

    .btn-upload {
      @extend .text-xs;
      color: var(--gray-modern-500);
      height: 36px;
      min-width: 95px !important;
      .upload-icon {
        stroke: var(--gray-modern-500);
      }
    }

    .input-upoad {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}

.upload-file-button {
  position: relative;
  margin: 0;
  cursor: pointer;
  width: 95px;
  height: 36px;

  .input-upoad {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
}
