.status-select {
  @extend .text-medium-xs;
  box-shadow: none;

  .react-select__control {
    width: fit-content;
    min-height: unset;
    height: fit-content;
    border-radius: 16px;
    padding: 2px 8px;
    gap: 15px;
    justify-content: flex-start;
    box-shadow: none !important;

    // background-color: var(--gray-neutral-900);

    .react-select__value-container {
      padding: 0;
      display: flex;
      flex: none;

      .react-select__single-value,
      .react-select__input-container {
        @extend .text-medium-xs;
      }
    }

    .react-select__indicators {
      .react-select__indicator-separator {
        display: none;
      }
      .react-select__indicator,
      .react-select__dropdown-indicator {
        padding: 0;
      }
    }
  }

  &.read-only {
    .react-select__indicators {
      display: none;
    }
  }

  .react-select__menu {
    .react-select__option {
      color: var(--gray-modern-900);
      &:hover {
        background-color: var(--primary-200);
      }
      &:active {
        background-color: var(--primary-400);
      }

      &.react-select__option--is-focused {
        background-color: var(--primary-200);
      }

      &.react-select__option--is-selected {
        background-color: var(--primary-400);
      }
    }
  }

  &.blue-100 {
    .react-select__control {
      border: 1px solid var(--blue-100);
      background-color: var(--blue-100);

      .react-select__value-container {
        .react-select__single-value,
        .react-select__input-container {
          color: var(--blue-600);
        }

        .react-select__placeholder {
          color: var(--blue-600);
        }
      }

      .react-select__indicators {
        .react-select__indicator,
        .react-select__dropdown-indicator {
          color: var(--blue-600);
        }
      }
    }
  }

  &.blue-600 {
    .react-select__control {
      border: 1px solid var(--blue-600);
      background-color: var(--blue-600);

      .react-select__value-container {
        .react-select__single-value,
        .react-select__input-container {
          color: var(--base-white);
        }

        .react-select__placeholder {
          color: var(--base-white);
        }
      }

      .react-select__indicators {
        .react-select__indicator,
        .react-select__dropdown-indicator {
          color: var(--base-white);
        }
      }
    }
  }

  &.gray-modern-200 {
    .react-select__control {
      border: 1px solid var(--gray-modern-200);
      background-color: var(--gray-modern-200);

      .react-select__value-container {
        .react-select__single-value,
        .react-select__input-container {
          color: var(--gray-modern-500);
        }

        .react-select__placeholder {
          color: var(--gray-modern-500);
        }
      }

      .react-select__indicators {
        .react-select__indicator,
        .react-select__dropdown-indicator {
          color: var(--gray-modern-500);
        }
      }
    }
  }

  &.green-500 {
    .react-select__control {
      border: 1px solid var(--green-500);
      background-color: var(--green-500);

      .react-select__value-container {
        .react-select__single-value,
        .react-select__input-container {
          color: var(--base-white);
        }

        .react-select__placeholder {
          color: var(--base-white);
        }
      }

      .react-select__indicators {
        .react-select__indicator,
        .react-select__dropdown-indicator {
          color: var(--base-white);
        }
      }
    }
  }

  &.yellow-400 {
    .react-select__control {
      border: 1px solid var(--yellow-400);
      background-color: var(--yellow-400);

      .react-select__value-container {
        .react-select__single-value,
        .react-select__input-container {
          color: var(--base-white);
        }

        .react-select__placeholder {
          color: var(--base-white);
        }
      }

      .react-select__indicators {
        .react-select__indicator,
        .react-select__dropdown-indicator {
          color: var(--base-white);
        }
      }
    }
  }

  &.error-600 {
    .react-select__control {
      border: 1px solid var(--error-600);
      background-color: var(--error-600);

      .react-select__value-container {
        .react-select__single-value,
        .react-select__input-container {
          color: var(--base-white);
        }

        .react-select__placeholder {
          color: var(--base-white);
        }
      }

      .react-select__indicators {
        .react-select__indicator,
        .react-select__dropdown-indicator {
          color: var(--base-white);
        }
      }
    }
  }
}
