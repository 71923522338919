.post-card {
    border: 1px solid var(--gray-modern-200) !important;
    border-radius: 8px;
    box-shadow: none;
    background-color: white;
    margin-bottom: 32px;

    .post-content {
        padding: 20px;
    }

    .post-comment {
        margin: 0 20px;
        padding: 24px 0;
        display: grid;
        gap: 24px;
        border-top: 1px solid var(--gray-modern-200) !important;
    }

    .card-footer {
        background-color: var(--base-white);
        padding: 20px;
        display: flex;
        align-items: center;
        border-radius: 0 0 8px 8px;
        border-top: 1px solid var(--gray-modern-200) !important;
        @extend .comment-input;
    }

    .action-icon {
        background: transparent;
        box-shadow: none !important;
        color: var(--gray-modern-700);
        padding: 0;

        &:hover,
        &:active {
            background: transparent;
        }
    }

    .action-menu {
        border-radius: 8px;
        padding: 0;

        .dropdown-item {
            @extend .text-medium-sm;
            color: var(--gray-modern-700);
            padding: 10px 16px;
            display: flex;
            align-items: center;
            align-self: stretch;
            gap: 12px;

            &:active {
                color: var(--gray-modern-700);
                background-color: var(--cui-link-hover-color-rgb);
            }
        }

        li {
            &:first-child {
                .dropdown-item {
                    border-radius: 8px 8px 0 0;
                }
            }

            &:last-child {
                .dropdown-item {
                    border-radius: 0 0 8px 8px;
                }
            }
        }

        .dropdown-divider {
            border-top: 1px solid var(--gray-modern-300);
            margin: 0;
            opacity: unset;
        }
    }
}

.comment-input {
    .form-control {
        @extend .text-xs;
        border-radius: 18px;
        height: 32px !important;
        border: 1px solid var(--gray-modern-200) !important;
        border-right: none !important;
        padding: 6px 14px !important;
        background-color: var(--base-white);

        &:focus {
            box-shadow: none;
        }

        &::placeholder {
            color: var(--gray-modern-400) !important;
        }
    }

    .input-group-text {
        border-radius: 18px;
        border: 1px solid var(--gray-modern-200) !important;
        color: var(--gray-modern-400) !important;
        padding: 6px 12px !important;
        background-color: var(--base-white);
        cursor: pointer;
        height: 32px !important;

        &:hover {
            svg {
                color: var(--primary-500);
            }
        }
    }
}

.comment-content {
    border-radius: 18px;
    border: 1px solid var(--gray-modern-200);
    background: var(--gray-modern-25);
    padding: 8px 12px;
    display: inline-block;
}

.emoji-container {
    div {
        z-index: 2;
        position: relative;
    }
}