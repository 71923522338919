// .btn-primary,
// .btn-primary:hover,
// .btn-primary:active,
// .btn-primary:visited {
//   background-color: var(--primary-700) !important;
// }

.btn-custom {
  @extend .text-medium-md;
  border-radius: 8px;
  gap: 8px;
  padding: 10px 18px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  box-shadow: none !important;

  &.rounded-24 {
    border-radius: 24px;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.align-end {
    align-items: flex-end;
  }

  &.flex-start {
    justify-content: flex-start;
  }

  &.flex-end {
    justify-content: flex-end;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.btn-2xs {
    @extend .text-semibold-xs;
    padding: 4px 14px;
    min-width: 48px;
    width: fit-content;
  }

  &.btn-xs {
    @extend .text-semibold-sm;
    padding: 8px 14px;
    min-width: 88px;
    width: fit-content;
  }

  &.btn-sm {
    @extend .text-semibold-sm;
    padding: 10px 16px;
  }

  &.btn-md {
    @extend .text-semibold-sm;
    padding: 10px 16px;
  }

  &.btn-lg {
    @extend .text-semibold-md;
    padding: 12px 20px;
  }

  &.btn-xl {
    @extend .text-semibold-lg;
    padding: 16px 28px;
    gap: 12px;
  }

  &.min-width-160 {
    min-width: 160px;
  }

  &.icon-btn {
    border-radius: 8px;
    padding: 12px;
    &.icon-btn-xs {
      padding: 8px;
    }
    &.icon-btn-sm {
      padding: 10px;
    }
    &.icon-btn-lg {
      padding: 14px;
    }
    &.icon-btn-xl {
      padding: 16px;
    }
  }

  &.primary-500 {
    border: 1px solid var(--primary-500);
    border-color: var(--primary-500) !important;
    background-color: var(--primary-500);
    color: var(--base-white);

    svg {
      stroke: var(--base-white);
    }

    &:hover,
    &:active {
      color: var(--base-white);
      border: 1px solid var(--primary-700);
      border-color: var(--primary-700) !important;
      background-color: var(--primary-700);
    }
    &:disabled {
      color: var(--base-white);
      border: 1px solid var(--primary-200);
      border-color: var(--primary-200) !important;
      background-color: var(--primary-200);
    }
  }

  &.error-500 {
    border: 1px solid var(--error-600);
    border-color: var(--error-600) !important;
    background-color: var(--error-500);
    color: var(--base-white);

    svg {
      stroke: var(--base-white);
    }

    &:hover,
    &:active {
      color: var(--base-white);
      border: 1px solid var(--error-600);
      border-color: var(--error-600) !important;
      background-color: var(--error-600);
    }
    &:disabled {
      color: var(--base-white);
      border: 1px solid var(--error-200);
      border-color: var(--error-200) !important;
      background-color: var(--error-200);
    }
  }

  &.icon-white {
    border: 1px solid var(--base-white);
    border-color: var(--base-white) !important;
    background-color: var(--base-white);
    color: var(--gray-modern-700);

    svg {
      stroke: var(--gray-modern-700);
    }

    &:hover,
    &:active {
      color: var(--gray-modern-700);
      border: 1px solid var(--base-white);
      border-color: var(--base-white) !important;
      background-color: var(--base-white);
    }
    &:disabled {
      color: var(--gray-modern-300);
      border: 1px solid var(--primary-200);
      border-color: var(--primary-200) !important;
      background-color: var(--primary-200);
    }
  }

  &.variant-gray-300 {
    border: 1px solid var(--gray-modern-300);
    border-color: var(--gray-modern-300) !important;
    background-color: var(--base-white);
    color: var(--gray-modern-700);

    svg {
      stroke: var(--gray-modern-700);
    }

    &:hover,
    &:active {
      color: var(--gray-modern-700);
      border: 1px solid var(--gray-modern-300);
      border-color: var(--gray-modern-300) !important;
      background-color: var(--gray-modern-50);
    }
    &:disabled {
      color: var(--gray-modern-300);
      border: 1px solid var(--gray-modern-200);
      border-color: var(--gray-modern-200) !important;
      background-color: var(--base-white);
    }
  }

  &.variant-gray-neutral-500 {
    border: 1px solid var(--gray-modern-100);
    border-color: var(--gray-modern-100) !important;
    background-color: var(--gray-modern-100);
    color: var(--gray-neutral-500);

    svg {
      stroke: var(--gray-neutral-500);
    }

    &:hover,
    &:active {
      color: var(--gray-neutral-500);
      border: 1px solid var(--gray-modern-100);
      border-color: var(--gray-modern-100) !important;
      background-color: var(--gray-modern-100);
    }
    &:disabled {
      color: var(--gray-modern-300);
      border: 1px solid var(--gray-modern-200);
      border-color: var(--gray-modern-200) !important;
      background-color: var(--base-white);
    }
  }

  &.gray-modern-600 {
    border: 1px solid var(--gray-modern-600);
    border-color: var(--gray-modern-600) !important;
    background-color: var(--gray-modern-600);
    color: var(--base-white);

    svg {
      stroke: var(--base-white);
    }

    &:hover,
    &:active {
      color: var(--base-white);
      border: 1px solid var(--gray-modern-600);
      border-color: var(--gray-modern-600) !important;
      background-color: var(--gray-modern-600);
    }
    &:disabled {
      color: var(--base-white);
      border: 1px solid var(--gray-modern-300);
      border-color: var(--gray-modern-300) !important;
      background-color: var(--gray-modern-300);
    }
  }

  &.variant-transparent-white {
    border: 1px solid transparent;
    border-color: transparent !important;
    background-color: transparent;
    color: var(--base-white);

    svg {
      stroke: var(--base-white);
    }

    &:hover,
    &:active {
      color: var(--base-white);
      border: 1px solid transparent;
      border-color: transparent !important;
      background-color: transparent;
    }
    &:disabled {
      color: var(--base-white);
      border: 1px solid transparent;
      border-color: transparent !important;
      background-color: transparent;
    }
  }

  // Old style check new style for button

  // &.btn-xl {
  //   width: 240px;
  // }

  // &.rounded-btn {
  //   border-radius: 360px;
  //   &.rounded-sm {
  //     padding: 4px 8px;
  //   }
  // }

  // &.gray-700 {
  //   background-color: var(--gray-700);
  //   border: 1px solid var(--gray-700);
  //   color: var(--gray-50);
  //   &:hover,
  //   &:active,
  //   &:visited {
  //     background-color: var(--gray-700);
  //     border: 1px solid var(--gray-700);
  //     color: var(--gray-50);
  //   }
  // }

  // &.primary-900 {
  //   background-color: var(--primary-900);
  //   color: var(--primary-700);
  //   border: 1px solid var(--primary-900);
  //   &:hover,
  //   &:active,
  //   &:visited {
  //     background-color: var(--primary-900);
  //     color: var(--primary-700);
  //     border: 1px solid var(--primary-900);
  //   }
  // }

  // &.primary-500 {
  //   background-color: var(--primary-500);
  //   color: var(--primary-700);
  //   border: 1px solid var(--primary-500);
  //   &:hover,
  //   &:active,
  //   &:visited {
  //     background-color: var(--primary-500);
  //     color: var(--primary-700);
  //     border: 1px solid var(--primary-500);
  //   }
  // }
}

.dropdown-custom {
  .dropdown-menu {
    border-radius: 8px;
    padding: 0;
    border: 1px solid var(--gray-modern-300);

    .dropdown-header {
      display: flex;
      padding: 12px 16px;
      align-items: center;
      gap: 12px;
      .header-title {
        @extend .text-semibold-sm;
        color: var(--gray-modern-700);
      }

      .header-note {
        @extend .text-sm;
        color: var(--gray-modern-500);
      }
    }

    .dropdown-item {
      @extend .text-medium-sm;
      color: var(--gray-modern-700);
      padding: 10px 16px;
      display: flex;
      align-items: center;
      align-self: stretch;
      gap: 12px;
      &:active {
        color: var(--gray-modern-700);
        background-color: var(--cui-link-hover-color-rgb);
      }
      .dropdown-icon {
        cursor: pointer;
        stroke: var(--gray-modern-700);
      }
    }

    .dropdown-divider {
      border-top: 1px solid var(--gray-modern-300);
      margin: 0;
      opacity: unset;
    }
  }
}
