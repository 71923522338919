.wrapper {
  width: 100%;
  height: 100%;
  @include ltr-rtl('padding-left', var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding 0.15s);
}

.body {
  background-color: var(--base-white);
  font-family: 'Times New Roman', Times, serif !important;
}

body,
html {
  height: 100%;
}

.background-gradient {
  /* ff 3.6+ */
  background: -moz-linear-gradient(0deg, #ff5c00 0%, rgba(253, 168, 75, 1) 100%);

  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(0deg, #ff5c00 0%, rgba(253, 168, 75, 1) 100%);

  /* opera 11.10+ */
  background: -o-linear-gradient(0deg, #ff5c00 0%, rgba(253, 168, 75, 1) 100%);

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FF5C00', endColorstr='#FDA84B', GradientType=1 );

  /* ie 10+ */
  background: -ms-linear-gradient(0deg, #ff5c00 0%, rgba(253, 168, 75, 1) 100%);

  /* global 94%+ browsers support */
  background: linear-gradient(0deg, #ff5c00 0%, rgba(253, 168, 75, 1) 100%);
}

.cursor-pointer {
  cursor: pointer;
}

.bg-primary-500 {
  background-color: var(--primary-500);
}

.icon-fill {
  fill: currentcolor;
  color: inherit;
  text-align: center;
  display: inline-block;
}

.icon-stroke {
  stroke: currentcolor;
  color: inherit;
  text-align: center;
  display: inline-block;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background: #4b5565;
  border-radius: 5px;
  &:focus,
  &:active,
  &:hover {
    background: #4b5565;
  }
}
::-webkit-scrollbar-track {
  background: #f8fafc;
  border-radius: 5px;
}


/// ADMIN
.green-fade-btn {
  background-color: #e5e5e5;
}

.profileImageContainer {
  position: relative;

  .p-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      display: none;
    }
  }
}

.bg-cms {
  background-color: transparent !important;
}