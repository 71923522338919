.custom-badge {
  @extend .text-medium-sm;
  border-radius: 16px;
  padding: 2px 10px;
  // gap: 4px;
  // display: flex;
  align-items: center;
  justify-content: center;

  &.badge-sm {
    @extend .text-medium-xs;
    padding: 2px 8px;
  }

  &.badge-lg {
    padding: 4px 12px;
  }

  &.badge-noborder {
    @extend .text-medium-xs;
    display: inline-flex;
    padding: 2px 8px 2px 6px;
    border: none;
    background-color: transparent;
    color: var(--gray-modern-500);
    gap: 6px;
    &.dot-primary {
      svg {
        color: var(--primary-700);
      }
    }

    &.dot-success {
      svg {
        color: var(--success-600);
      }
    }

    &.dot-blue {
      svg {
        color: var(--blue-600);
      }
    }

    &.dot-warning {
      svg {
        color: var(--warning-600);
      }
    }

    &.dot-danger {
      svg {
        color: var(--error-600);
      }
    }

    &.dot-gray {
      svg {
        color: var(--gray-neutral-700);
      }
    }

    &.dot-purple {
      svg {
        color: var(--purple-700);
      }
    }

    &.dot-pink {
      svg {
        color: var(--pink-700);
      }
    }

    &.dot-rose {
      svg {
        color: var(--rose-700);
      }
    }

    &.dot-orange {
      svg {
        color: var(--orange-700);
      }
    }
  }

  &.primary {
    // border: 1px solid var(--primary-800);
    background-color: var(--primary-50);
    color: var(--primary-700);
  }

  &.success {
    // border: 1px solid var(--success-800);
    background-color: var(--success-50);
    color: var(--success-600);
  }

  &.blue {
    // border: 1px solid var(--info-800);
    background-color: var(--blue-50);
    color: var(--blue-600);
  }

  &.warning {
    // border: 1px solid var(--warning-800);
    background-color: var(--warning-50);
    color: var(--warning-600);
  }

  &.danger {
    // border: 1px solid var(--danger-800);
    background-color: var(--error-50);
    color: var(--error-600);
  }

  &.gray {
    // border: 1px solid var(--gray-100);
    background-color: var(--gray-neutral-100);
    color: var(--gray-neutral-700);
  }

  &.purple {
    // border: 1px solid var(--gray-100);
    background-color: var(--purple-50);
    color: var(--purple-700);
  }

  &.pink {
    // border: 1px solid var(--gray-100);
    background-color: var(--pink-50);
    color: var(--pink-700);
  }

  &.rose {
    // border: 1px solid var(--gray-100);
    background-color: var(--rose-50);
    color: var(--rose-700);
  }

  &.orange {
    // border: 1px solid var(--gray-100);
    background-color: var(--orange-50);
    color: var(--orange-700);
  }
}

.custom-active-badge {
  border-radius: 6px;
  padding: 4px 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.total-badge {
  border-radius: 16px;
  padding: 4px 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

// .badge-secondary {
//   color: #364152;
//   background-color: #eef2f6;
// }

// .badge-success {
//   color: #027a48;
//   background-color: #ecfdf3;
// }

// .badge-danger {
//   color: #b42318;
//   background-color: #fef3f2;
// }

// .badge-warning {
//   color: #b54708;
//   background-color: #fffaeb;
// }

// .badge-info {
//   color: #026aa2;
//   background-color: #f0f9ff;
// }

// .badge-default {
//   color: #3f621a;
//   background-color: #f5fbee;
// }
