.text-primary {
  color: var(--primary-500) !important;
}

.text-success {
  color: var(--success-500) !important;
}

.text-danger {
  // color: var(--danger-500) !important;
}

.text-warning {
  color: var(--warning-300) !important;
}

.text-info {
  color: var(--info-500) !important;
}

.text-gray-modern-50 {
  color: var(--gray-modern-50) !important;
}

.text-gray-modern-400 {
  color: var(--gray-modern-400) !important;
}

.text-gray-modern-500 {
  color: var(--gray-modern-500) !important;
}

.text-gray-modern-700 {
  color: var(--gray-modern-700) !important;
}

.text-gray-modern-900 {
  color: var(--gray-modern-900) !important;
}

.text-gray-neutral-700 {
  color: var(--gray-neutral-700) !important;
}

.text-gray-neutral-950 {
  color: var(--gray-neutral-950) !important;
}
